import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The blue buttons below the header are the "action buttons" providing very
useful functions.`}</p>
    <Image src="features/activitypagetop2.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Write a review`}</h4>
    <p>{`With just a few clicks you can say your opinion about this activity.`}</p>
    <Link to="/features/reviews" mdxType="Link">Find out more about reviews here.</Link>
    <br />
    <br />
    <h4>{`Report conditions`}</h4>
    <p>{`Very useful for everyone to know how the actual conditions are. Just spend a
few clicks and you helped with your feedback. `}<Link to="/features/showconditions" mdxType="Link">{`Find out more about condition reports here.`}</Link></p>
    <br />
    <br />
    <h4>{`Share this activity.`}</h4>
    <p>{`Share the activity easily on sosial media, messenger services, email, or copy
to clipboard, to share the link.`}</p>
    <br />
    <h4>{`Download the GPX track`}</h4>
    <Link to="/features/downloadtrack" mdxType="Link">
  Find out more about downloading tracks and navigation here.
    </Link>
    <br />
    <br />
    <h4>{`User page`}</h4>
    <p>{`Click on the user icon to open the users page or to contact the user.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/features/activitytabs" mdxType="Link">
  ← Tabs
    </Link>
    <Link className="float-right" to="/features/mapselector" mdxType="Link">
  Map selector →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      